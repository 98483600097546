<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
    <div class="home-conventions">
      <div class="overlay">
        <v-container>
        <br />
         <br />
        <div class="display-4">
          <span class="thick">Conventions</span>
        </div>
      </v-container>
      </div>
    </div>
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">New levels of audience participation and interaction</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Bluetooth messaging</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Crowd control</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Real-time audience data and feedback</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Easily change messages</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: false,
          exact: true,
          to: '/industries'
        },
        {
          text: 'Conventions',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>